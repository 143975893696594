import Raven from 'Raven';
import I18n from 'I18n'; // @ts-expect-error not yet converted

import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider'; // @ts-expect-error not yet converted

import BasicI18nSetup from 'I18n/init/setup/BasicI18nSetup';
import lang from 'i2l?query=sporks!../../lang/en.lyaml';
import { debugLog } from 'video-embed/utils/debug';
const DEFAULT_I18N_LOCALE = 'en';
export const loadPublicI18n = pageLocale => {
  let publicI18nProvider;

  if (pageLocale && pageLocale !== DEFAULT_I18N_LOCALE) {
    publicI18nProvider = createPublicI18nProvider({
      manuallySetLocale: true
    });
    publicI18nProvider.setLocale({
      langEnabled: true,
      locale: pageLocale
    });
    debugLog('I18n using locale based on CMS page language:', pageLocale);
  } else {
    publicI18nProvider = createPublicI18nProvider();

    if (publicI18nProvider.localePromise) {
      publicI18nProvider.localePromise.then(langCodes => {
        debugLog('I18n detected locale based on browser settings:', langCodes);
      }).catch(err => Raven.captureException(err));
    }
  }

  return publicI18nProvider.register(lang);
};
export const loadInAppPreviewI18n = auth => {
  return BasicI18nSetup.setup(Object.assign({}, auth, {
    lang
  }));
};

const getKey = key => `videoPlayer.controls.${key}`;

export function setupTranslations() {
  // based on https://github.com/videojs/video.js/blob/main/lang/en.json
  // in the future this could support translating the player if `?locale=` can be passed in via embed JS or inferred some other way
  return {
    'Audio Player': I18n.text(getKey('audioPlayer')),
    'Video Player': I18n.text(getKey('videoPlayer')),
    Play: I18n.text(getKey('play')),
    Pause: I18n.text(getKey('pause')),
    Replay: I18n.text(getKey('replay')),
    'Current Time': I18n.text(getKey('currentTime')),
    Duration: I18n.text(getKey('duration')),
    'Remaining Time': I18n.text(getKey('remainingTime')),
    Fullscreen: I18n.text(getKey('fullscreen')),
    'Non-Fullscreen': I18n.text(getKey('exitFullscreen')),
    Mute: I18n.text(getKey('mute')),
    Unmute: I18n.text(getKey('unmute')),
    'Volume Level': I18n.text(getKey('volumeLevel')),
    'Play Video': I18n.text(getKey('playVideo')),
    Captions: I18n.text(getKey('captions')),
    'captions off': I18n.text(getKey('captionsOff'))
  };
}