import { lWindow } from '../common/helpers';

// eslint-disable-next-line react-native-compatible-library/no-web-globals
const createBrowserHelper = localWindow => {
  // This one requires a Type Guard as Raven is not defined standarly
  // within the `window` object hence why the `prop` is set to `any`
  // This is a TypeScript limitation as TS cannot infer the Factory argument
  // And pass down to the sibling usages of this function
  const hasRaven = prop => {
    return typeof prop['Raven'] === 'object' && typeof prop['Raven'].captureException === 'function' && typeof prop['Raven'].captureMessage === 'function';
  }; // If the variable is `undefined` we don't need to execute each function
  // Since it is obvious that it will be `false`


  if (typeof localWindow === 'undefined') {
    return {
      hasRaven,
      hasLocalStorage: false,
      hasCookieAccess: false,
      hasBeaconSupport: false
    };
  }

  const hasLocalStorage = () => {
    try {
      return Boolean(localWindow.localStorage);
    } catch (err) {
      // window.localStorage might throw an exception if you're within an `iframe`
      // As the `window` will deny your access to the localStorage
      return false;
    }
  };

  const hasCookieAccess = () => {
    const {
      navigator,
      document
    } = localWindow;

    try {
      return typeof navigator === 'object' && navigator.cookieEnabled || typeof document === 'object' && Boolean(document.cookie);
    } catch (err) {
      // document.cookie might throw an exception if you're within an `iframe`
      // As the `document` will deny your access on the document cookie
      return false;
    }
  };

  const hasBeaconSupport = () => {
    const {
      navigator
    } = localWindow;

    try {
      return typeof navigator === 'object' && typeof navigator['sendBeacon'] === 'function';
    } catch (err) {
      // navigator.sendBeacon might throw an exception if you're within an `iframe`
      // As the `navigator` will deny your access on sendBeacon capabilities
      return false;
    }
  };

  return {
    hasRaven,
    hasLocalStorage: hasLocalStorage(),
    hasCookieAccess: hasCookieAccess(),
    hasBeaconSupport: hasBeaconSupport()
  };
};

const {
  hasRaven,
  hasLocalStorage,
  hasCookieAccess,
  hasBeaconSupport // Creates a default Browser Helper using the Window object
  // eslint-disable-next-line react-native-compatible-library/no-web-globals

} = createBrowserHelper(typeof lWindow !== 'undefined' ? lWindow : undefined);
export { hasRaven, hasLocalStorage, hasCookieAccess, hasBeaconSupport };
export default createBrowserHelper;