// eslint-disable-next-line hubspot-dev/no-declarations
export const PlayerStatus = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  READY: 'READY',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  ENDED: 'ENDED',
  CONVERSION_ASSET_OVERLAY: 'CONVERSION_ASSET_OVERLAY',
  ERROR: 'ERROR'
};
export const PlayButtonShapes = {
  CIRCLE: 'CIRCLE',
  ROUNDED_SQUARE: 'ROUNDED_SQUARE',
  SQUARE: 'SQUARE'
};
export let ConversionAssetTypes;

(function (ConversionAssetTypes) {
  ConversionAssetTypes["FORM"] = "FORM";
  ConversionAssetTypes["CTA"] = "CTA";
})(ConversionAssetTypes || (ConversionAssetTypes = {}));

export let ConversionAssetPositions;

(function (ConversionAssetPositions) {
  ConversionAssetPositions["PRE"] = "PRE";
  ConversionAssetPositions["POST"] = "POST";
  ConversionAssetPositions["CUSTOM"] = "CUSTOM";
})(ConversionAssetPositions || (ConversionAssetPositions = {}));

export const PlayerTypes = {
  DEFAULT: 'hsvideo-mux',
  // default implicit type (just for usage tracking)
  VIDEOJS_RAW_URL: 'videojs-raw-url',
  // for non-embeddable video or when lacking a Mux `primaryPlaybackId` (just for usage tracking)
  HTML5: 'html5',
  // used for non-embeddable aka "raw" videos, or when `?playerType=html5` query param used
  IN_APP_PREVIEW: 'inAppPreview',
  // general in-app preview player use case, default when VideoPlayer component is used
  EDITOR_PREVIEW: 'editorPreview',
  // players in content editor previews, start the same as rich text but can be upgraded into a Mux player & play on click
  RICH_TEXT_PREVIEW: 'richTextPreview' // players in tinymce content being actively edited show a non-interactive poster image

};