'use es6';

import Raven from 'Raven';
import enviro from 'enviro';
import { getDeepClone } from './ObjectHelpers';
const RAVEN_REQUEST_DATA_LENGTH_LIMIT = 1000;

const isDebug = () => enviro.debug('sentry');

const ERROR_TYPES = {
  quickFetch: 'quickFetch',
  hubHttp: 'hubHttp'
};

function getErrorType(err) {
  if (typeof err.status === 'number') {
    if (err instanceof XMLHttpRequest) {
      return ERROR_TYPES.quickFetch;
    } else if (err.options) {
      return ERROR_TYPES.hubHttp;
    }
  }

  return null;
}

function isFailedRequest(err) {
  return [ERROR_TYPES.hubHttp, ERROR_TYPES.quickFetch].includes(getErrorType(err));
}

export function reportError(err, opts = {
  errorId: null,
  tags: {},
  extra: {}
}) {
  const errorId = opts.errorId;
  const errorType = getErrorType(err);
  let tags = Object.assign({}, opts.tags, {
    errorId,
    errorType
  });
  const extra = opts.extra || {};
  let customErrorMessage;

  if (isFailedRequest(err)) {
    tags = Object.assign({}, tags, {
      status: err.status,
      errorCode: err.errorCode
    });

    if (errorType === ERROR_TYPES.quickFetch) {
      customErrorMessage = `Failed quickFetch request for ${errorId} with status: ${err.status}`;
    } else if (errorType === ERROR_TYPES.hubHttp && err.options) {
      tags = Object.assign({}, tags, {
        requestUrl: err.options.url,
        requestMethod: err.options.method
      });
      customErrorMessage = `Failed hub-http request for ${errorId} with status: ${err.status}`;

      if (err.options.data && typeof err.options.data === 'string') {
        extra.requestBody = err.options.data.substr(0, RAVEN_REQUEST_DATA_LENGTH_LIMIT);
      }

      if (typeof err.responseText === 'string') {
        extra.responseBody = err.responseText.substr(0, RAVEN_REQUEST_DATA_LENGTH_LIMIT);
      }
    }
  }

  const ravenOptions = getDeepClone({
    extra,
    tags
  }, true);

  if (errorId) {
    Raven.captureMessage(customErrorMessage || errorId, ravenOptions);

    if (isDebug()) {
      console.log(`Reported message: ${customErrorMessage}`, ravenOptions);
    }
  } else {
    Raven.captureException(err, ravenOptions);

    if (isDebug()) {
      console.log(`Reported error:`, err, ravenOptions);
    }
  }
}
export const wrapRequest = (promise, requestId) => {
  if (!requestId) {
    console.warn('requestId is required');
    return promise;
  }

  const requestStarted = new Date().getTime();
  Raven.captureBreadcrumb({
    type: 'message',
    message: `[wrapRequest] init ${requestId} at ${requestStarted}`
  });
  return promise.catch(err => {
    const elapsedMillis = new Date().getTime() - requestStarted;
    reportError(err, {
      errorId: requestId,
      extra: {
        requestStarted,
        elapsedMillis
      }
    });
    throw err;
  });
};