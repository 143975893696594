import { PLAYER_IFRAME_PASSTHRU_PARAMS } from '../constants';
import { ConversionAssetPositions, ConversionAssetTypes, PlayerTypes } from '../types';
import { getGlobalOptions, getHsVarsRendererContext, getHublet, getPageLanguage, isCmsEditorPreview, parseContentIdFromBody } from './cms';
import * as envUtils from './env';
import { pick } from './object';
import { debugLog, isDebugEnabled } from './debug';
import { reportMessage } from './errors';
export function parseQueryParams(queryString = window.location.search) {
  const queryObj = {};

  if (queryString[0] === '?') {
    queryString = queryString.substring(1);
  }

  queryString.split('&').forEach(pair => {
    const [key, val] = pair.split('=');

    if (key && val) {
      try {
        queryObj[key] = decodeURIComponent(val);
      } catch (e) {
        debugLog(`Could not decode query param: ${pair}`);
        queryObj[key] = val;
      }
    }
  });
  return queryObj;
}
export function stringifyQueryParams(queryObj) {
  const truthyQueryObj = {};
  Object.keys(queryObj).forEach(key => {
    if (queryObj[key] || typeof queryObj[key] === 'number') {
      truthyQueryObj[key] = queryObj[key];
    }
  });
  return Object.keys(truthyQueryObj).map(key => `${key}=${encodeURIComponent(truthyQueryObj[key])}`).join('&');
}

function queryToString(params) {
  return typeof params === 'object' && Object.keys(params).length ? `?${stringifyQueryParams(params)}` : '';
}

export function parseBoolParam(valueStr) {
  return valueStr === 'true' || valueStr === '1';
}
const CONVERSION_ASSET_KEYS = ['type', 'id', 'position', 'overlayDelaySeconds', 'preventSkip'];
export function serializeConversionAsset(asset) {
  return CONVERSION_ASSET_KEYS.map(key => asset[key] ? String(asset[key]) : '').join('_');
}
export function deserializeConversionAsset(assetStr) {
  if (assetStr[0] === '{') {
    try {
      return JSON.parse(assetStr);
    } catch (e) {
      console.warn('Could not parse conversionAsset param JSON', assetStr, e);
      return null;
    }
  }

  if (assetStr.includes('_')) {
    const asset = {};
    assetStr.split('_').forEach((val, i) => {
      if (val) {
        asset[CONVERSION_ASSET_KEYS[i]] = val;
      }
    });

    if (asset.preventSkip) {
      asset.preventSkip = parseBoolParam(asset.preventSkip);
    }

    if (asset.overlayDelaySeconds) {
      asset.overlayDelaySeconds = Number(asset.overlayDelaySeconds);
    }

    return asset;
  }

  return null;
}
export function buildIframeQueryParams(options) {
  const hsVars = getHsVarsRendererContext();
  const pageId = hsVars && typeof hsVars.page_id === 'number' ? hsVars.page_id : parseContentIdFromBody();
  const iframeParams = Object.assign({}, pick(options, PLAYER_IFRAME_PASSTHRU_PARAMS), {
    parentOrigin: window.location.origin,
    pageId,
    locale: getPageLanguage()
  });

  if (!iframeParams.parentOrigin) {
    reportMessage(`window.location is missing - ${document.documentURI}`);
  }

  if (isDebugEnabled()) {
    iframeParams.debug = true;
  }

  if (isCmsEditorPreview()) {
    iframeParams.playerType = PlayerTypes.EDITOR_PREVIEW;
  }

  const queryParams = parseQueryParams();
  const globalOptions = getGlobalOptions();

  if (globalOptions.hidePosterImage) {
    iframeParams.hidePosterImage = true;
  }

  if (queryParams._hsVideoForm) {
    options.conversionAsset = {
      type: ConversionAssetTypes.FORM,
      position: ConversionAssetPositions.PRE,
      id: queryParams._hsVideoForm
    };
  } else if (queryParams._hsVideoCta) {
    options.conversionAsset = {
      type: ConversionAssetTypes.CTA,
      position: ConversionAssetPositions.PRE,
      id: queryParams._hsVideoCta
    };
  }

  if (typeof options.conversionAsset === 'object' && options.conversionAsset.type && options.conversionAsset.id) {
    iframeParams.ca = serializeConversionAsset(options.conversionAsset);
  }

  if (iframeParams.playButtonColor) {
    iframeParams.playButtonColor = iframeParams.playButtonColor.replace('#', '');

    if (iframeParams.playButtonColor === 'ffffff') {
      // video widget defaults to white making triangle svg invisible, so ignore
      delete iframeParams.playButtonColor;
    }
  }

  const isFullWidthHeight = options.height === '100%' && (options.width === '100%' || options.full_width);

  if (isFullWidthHeight) {
    iframeParams.objectFit = 'cover';
  }

  return iframeParams;
}
export function getEmbedIframeUrl(portalId, videoId, params = {}, embedId) {
  let path = 'video';

  if (portalId && videoId) {
    path = envUtils.isAuthedAppPreview() ? `video/${portalId}/id/${videoId}` : `v/${portalId}/id/${videoId}`;
  } // need ?region= query param as bender-proxy doesn't support local-eu1.hubspotvideo(qa).com


  if (envUtils.isLocal() && !envUtils.isAuthedAppPreview() && getHublet()) {
    params.region = getHublet();
  } // we convey the embedId and extra metadata via a querystring style hash.


  const hash = embedId ? `#hsvid=${embedId}&t=${Date.now()}` : '';
  return `${envUtils.getEmbedIframeDomain()}/${path}${queryToString(params)}${hash}`;
}