/* hs-eslint ignored failing-rules */

/* eslint-disable prefer-const */
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { createClient } from 'usage-tracker-core';
import { genericClient } from 'usage-tracker-core/client';
const origin = getFullUrl('app-api');
const path = '/usage-logging/v1/log/hublytics-multi/no-auth';

const reportNetworkError = err => {
  return genericClient.reportError(err, {
    fingerprint: ['usage-tracker-js', 'network']
  });
};

const attemptToGetTrackerHstc = () => {
  return new Promise(resolve => {
    let interval;
    let timeout;
    interval = setInterval(() => {
      const hstc = genericClient.getHstc();

      if (hstc) {
        clearTimeout(timeout);
        clearInterval(interval);
        resolve(hstc);
      }
    }, 100);
    timeout = setTimeout(() => {
      if (!genericClient.getHstc()) {
        clearInterval(interval);

        if (genericClient.getDebug()) {
          console.warn('Could not get tracker hstc after 8 seconds');
        }

        resolve(null);
      }
    }, 8000);
  });
};

const publicTrackingClient = createClient({
  clientName: 'public',
  getDebug: genericClient.getDebug,
  getLang: genericClient.getLang,
  getTempStorage: genericClient.getTempStorage,
  setTempStorage: genericClient.setTempStorage,
  logError: genericClient.logError,
  logWarning: genericClient.logWarning,
  reportError: genericClient.reportError,
  getHstc: attemptToGetTrackerHstc,
  getEmail: () => null,
  getHubId: () => null,
  send: ({
    events,
    query,
    isBeforeUnload
  }) => {
    const endpoint = `${origin}${path}?${query}`;
    const data = events;

    const sendXhr = () => {
      noAuthApiClient.post(endpoint, {
        data
      }).catch(reportNetworkError);
    };

    if (isBeforeUnload) {
      genericClient.sendBeacon(endpoint, data, sendXhr);
    } else {
      sendXhr();
    }
  }
});
export default publicTrackingClient;