/* hs-eslint ignored failing-rules */

/* eslint-disable prefer-promise-reject-errors */
const HS_SCRIPT_LOADER_ID = 'hs-script-loader';
const HS_SCRIPT_LOADER_PATH_PREFIX = '/hs/scriptloader/';
export const VIDEO_EMBED_SRC_PATTERN = /video-embed\/ex\/loader(-qa)?.js/;
export const GENERIC_PLACEHOLDER_SELECTOR = 'div.hs-video-widget[data-hsv-embed-id]';
export const UNUSED_PLACEHOLDER_SELECTOR = `${GENERIC_PLACEHOLDER_SELECTOR}:not([data-hsv-status])`; // we still see legacy browsers which lack NodeList.forEach, Array.from etc

export function query(selector) {
  return [].slice.call(document.querySelectorAll(selector));
}
export function queryEl(el, selector) {
  return [].slice.call(el.querySelectorAll(selector));
}
export function getSelfScriptEl() {
  const scripts = query('script[src]');
  return scripts.find(scriptEl => VIDEO_EMBED_SRC_PATTERN.test(scriptEl.src) || scriptEl.src.endsWith('/video-embed/static-1.5773/bundles/loader.js'));
}
export function parsePortalIdFromScriptLoader() {
  const scriptLoaderEl = document.getElementById(HS_SCRIPT_LOADER_ID);

  if (scriptLoaderEl && scriptLoaderEl.src && scriptLoaderEl.src.includes(HS_SCRIPT_LOADER_PATH_PREFIX)) {
    const a = document.createElement('a');
    a.href = scriptLoaderEl.src;
    const portalIdStr = a.pathname.replace(HS_SCRIPT_LOADER_PATH_PREFIX, '').replace('.js', '');
    const portalId = Number(portalIdStr);

    if (portalId > 0) {
      return portalId;
    }
  }

  return null;
}
export function getSelfScriptSrc() {
  const scriptEl = getSelfScriptEl();
  return scriptEl ? scriptEl.src : null;
}
export function getEmbedPlaceholderEl(id, onlyUnused = false) {
  if (onlyUnused) {
    return document.querySelector(`[data-hsv-embed-id="${id}"]:not([data-hsv-status])`);
  }

  return document.querySelector(`[data-hsv-embed-id="${id}"]`);
}
export function hasDocLoaded() {
  return document.readyState === 'interactive' || document.readyState === 'complete';
}
const SCRIPT_LOAD_TIMEOUT_MS = 10000;
export function loadScript(src) {
  const script = document.createElement('script');
  return new Promise((resolve, reject) => {
    let loaded = false;

    script.onload = () => {
      loaded = true;
      resolve();
    };

    script.onerror = () => reject('loadScript failed');

    script.src = src;
    document.getElementsByTagName('head')[0].appendChild(script);
    setTimeout(() => {
      if (!loaded) {
        console.log(`Loading script ${src} timed out`);
        reject('loadScript timed out');
      }
    }, SCRIPT_LOAD_TIMEOUT_MS);
  });
}
export function setStyles(el, styleObj = {}) {
  if (el instanceof HTMLElement) {
    Object.assign(el.style, styleObj);
  }
}
export function hasParentWithClass(el, className) {
  if (!className.startsWith('.')) {
    className = `.${className}`;
  }

  while (el && el.parentElement) {
    // SVGElement className is not a simple string
    if (el.parentElement.matches && el.parentElement.matches(className)) {
      return true;
    }

    el = el.parentElement;
  }

  return false;
}
export function isVisible(el) {
  const rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}
export function isTransparent(el) {
  const {
    backgroundColor
  } = getComputedStyle(el);
  return backgroundColor === 'transparent' || backgroundColor === 'rgba(0, 0, 0, 0)';
}
/* eslint-disable no-bitwise */

export function getColorValue(hexColor) {
  // borrowed from https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
  const rgb = parseInt(hexColor.substring(1), 16);
  const r = rgb >> 16 & 0xff; // extract red

  const g = rgb >> 8 & 0xff; // extract green

  const b = rgb >> 0 & 0xff; // extract blue

  return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
}