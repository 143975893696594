module.exports = {
  "videoLoaded": {
    "name": "Video loaded",
    "class": "usage",
    "properties": {
      "success": {
        "type": "boolean",
        "isOptional": true
      },
      "statusCode": {
        "type": "number",
        "isOptional": true
      },
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "overlayPreventSkip": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "loadDelaySeconds": {
        "type": "number",
        "isOptional": true
      },
      "isEmbeddable": {
        "type": "boolean",
        "isOptional": true
      },
      "hasPrimaryPlaybackId": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "video-player",
    "version": "1"
  },
  "playerInteraction": {
    "name": "Player interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "overlayPreventSkip": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "loadDelaySeconds": {
        "type": "number",
        "isOptional": true
      },
      "isEmbeddable": {
        "type": "boolean",
        "isOptional": true
      },
      "hasPrimaryPlaybackId": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "video-player",
    "version": "1"
  },
  "videoAttentionSpan": {
    "name": "Video attention span",
    "class": "usage",
    "properties": {
      "viewDuration": "number",
      "completed": "boolean",
      "chunksLoaded": "number",
      "megaBytesLoaded": "number",
      "secondsLoaded": "number",
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "overlayPreventSkip": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "loadDelaySeconds": {
        "type": "number",
        "isOptional": true
      },
      "isEmbeddable": {
        "type": "boolean",
        "isOptional": true
      },
      "hasPrimaryPlaybackId": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "video-player",
    "version": "1"
  },
  "hlsLoadStarted": {
    "name": "HLS loading started",
    "class": "usage",
    "properties": {
      "secondsElapsed": "number",
      "reason": "string",
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "overlayPreventSkip": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "loadDelaySeconds": {
        "type": "number",
        "isOptional": true
      },
      "isEmbeddable": {
        "type": "boolean",
        "isOptional": true
      },
      "hasPrimaryPlaybackId": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "video-player",
    "version": "1"
  },
  "hlsChunksLoaded": {
    "name": "HLS chunks loaded",
    "class": "usage",
    "properties": {
      "initial": "boolean",
      "viewDuration": "number",
      "chunksLoaded": "number",
      "megaBytesLoaded": "number",
      "secondsLoaded": "number",
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "overlayPreventSkip": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "loadDelaySeconds": {
        "type": "number",
        "isOptional": true
      },
      "isEmbeddable": {
        "type": "boolean",
        "isOptional": true
      },
      "hasPrimaryPlaybackId": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "video-player",
    "version": "1"
  },
  "playbackFailed": {
    "name": "Playback failed",
    "class": "usage",
    "properties": {
      "phase": "string",
      "streamType": "string",
      "autoplay": "boolean",
      "loop": "boolean",
      "muted": "boolean",
      "hiddenControls": "boolean",
      "playerType": {
        "type": "string",
        "isOptional": true
      },
      "videoId": {
        "type": "number",
        "isOptional": true
      },
      "videoTitle": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "pageDomain": {
        "type": "string",
        "isOptional": true
      },
      "pageUrl": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetType": {
        "type": "string",
        "isOptional": true
      },
      "conversionAssetPosition": {
        "type": "string",
        "isOptional": true
      },
      "overlayPreventSkip": {
        "type": "boolean",
        "isOptional": true
      },
      "hostApp": {
        "type": "string",
        "isOptional": true
      },
      "renderContext": {
        "type": "string",
        "isOptional": true
      },
      "viaSharePage": {
        "type": "boolean",
        "isOptional": true
      },
      "hasUtk": {
        "type": "boolean",
        "isOptional": true
      },
      "loadDelaySeconds": {
        "type": "number",
        "isOptional": true
      },
      "isEmbeddable": {
        "type": "boolean",
        "isOptional": true
      },
      "hasPrimaryPlaybackId": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "video-player",
    "version": "1"
  }
};