import { getDefaultLanguage } from './langHelper';
import { isLocalDeployment, isQaDeployment, isProdDeployment, isDebugEnabled } from '../common/helpers';
import { getHstc } from '../common/defaultTrackers';
import tempStorage from '../containers/tempStorage';
import sendBeacon from '../sendBeacon';
import reportError from '../reportError'; // These are reusable/shared client dependencies that can be used by any client implementation

const genericClient = {
  getHstc,
  sendBeacon,
  reportError,
  getDebug: isDebugEnabled,
  getLang: getDefaultLanguage,
  getTempStorage: tempStorage.getItem,
  setTempStorage: tempStorage.setItem,
  logError: err => {
    if (isDebugEnabled() && !isProdDeployment()) {
      console.error(err);
    }
  },
  logWarning: warning => {
    if (isLocalDeployment() || isQaDeployment() || isDebugEnabled()) {
      console.warn(warning);
    }
  }
};
export default genericClient;