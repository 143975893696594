import { createSelector } from 'reselect';
import { CAPTIONS_GATE } from '../constants';
export const getGates = state => state.video.gates;
export const getParentPageOverlaysUngated = createSelector([getGates], gates => {
  if (!gates) {
    return false;
  }

  return gates.includes('DAM:VideoAdvancedLeadGen') || gates.includes('DAM:Video:ParentPageOverlays');
});
export const getLazyLoadingUngated = createSelector([getGates], gates => {
  if (!gates) {
    return false;
  }

  return gates.includes('DAM:Video:LazyLoading');
});
export const getIsUngatedForCaptions = createSelector([getGates], gates => {
  if (!gates) {
    return false;
  }

  return gates.includes(CAPTIONS_GATE);
});
export const getAppError = state => state.video.appError;
export const getVideo = state => state.video.video;
export const getVideoFetchError = state => state.video.videoFetchError;
export const getConfirmedVisible = state => state.video.confirmedVisible;
export const getParams = state => state.params;
export const getPlayerType = createSelector([getParams], params => params.playerType);
export const getCaptions = state => state.captions.captions;