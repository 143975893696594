// @ts-expect-error not converted yet
import { reportError } from 'ContentUtils/helpers/sentry';
import { fetchCaptionUrls } from 'video-data-lib/api/captions';
import { FETCH_CAPTION_URLS_ATTEMPTED, FETCH_CAPTION_URLS_FAILED, FETCH_CAPTION_URLS_SUCCEEDED } from './ActionTypes';
export const loadCaptions = videoId => dispatch => {
  dispatch({
    type: FETCH_CAPTION_URLS_ATTEMPTED
  });
  return fetchCaptionUrls(videoId).then(captions => dispatch({
    type: FETCH_CAPTION_URLS_SUCCEEDED,
    captions
  })).catch(err => reportError(err, {
    errorId: FETCH_CAPTION_URLS_FAILED
  }));
};