import { HEFFALUMP } from 'HubStyleTokens/colors';
import * as EmbedTypes from 'video-embed/types';
export const VIDEOJS_LOAD_TIMEOUT_MS = 20 * 1000;
export const DEFAULT_PLAY_BUTTON_COLOR = HEFFALUMP;
export const PLAY_BUTTON_SVG = `
  <svg viewBox="0 0 32 32" fill="#ffffff" focusable="false" role="img">
    <path id="play" data-testid="play" d="M6.484 4.094l20.75 11.225c0.226 0.121 0.41 0.427 0.41 0.683s-0.184 0.563-0.41 0.683l-20.75 11.222c-0.095 0.051-0.26 0.093-0.367 0.093-0.427 0-0.774-0.346-0.774-0.773v-22.451c0-0.428 0.347-0.774 0.774-0.774 0.108 0 0.272 0.042 0.367 0.093z" />
  </svg>
`;
export const CAPTIONS_GATE = 'DAM:Video2Captions';
export const PlayButtonShapes = {
  CIRCLE: 'CIRCLE',
  ROUNDED_SQUARE: 'ROUNDED_SQUARE',
  SQUARE: 'SQUARE'
};
export const PlayerStatus = EmbedTypes.PlayerStatus;
export const PlayerErrorTypes = {
  VIDEO_NOT_FOUND: 'VIDEO_NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  INVALID_URL: 'INVALID_URL',
  UNSUPPORTED_EMBED: 'UNSUPPORTED_EMBED'
};
export const PLAYER_OPTIONS = ['posterUrl', 'playButtonColor', 'autoplay', 'loop', 'hiddenControls', 'muted', 'locale', // from window.hsVideoOptions
'hidePosterImage'];
export const SHARED_PLAYER_DOMAINS_PATTERNS = [/(app|local).hubspot(qa)?.com/, /(app|local)-(\w+).hubspot(qa)?.com/, /(play|local).hubspotvideo(qa)?.com/, /(play|local)-(\w+).hubspotvideo(qa)?.com/];