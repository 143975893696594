
(function() {
  var result = { translations: { "ru": { "i18n": { "duration": { "seconds": "{{ seconds }} \u0441", "minutes": "{{ minutes }} \u043C\u0438\u043D", "hours": "{{ hours }} \u0447", "hoursAndMinutes": "{{ hours }} \u0447 {{ minutes }} \u043C\u0438\u043D", "days": "{{ days }} \u0434\u043D.", "shortForm": { "seconds": "{{ seconds }}\u0441", "minutes": "{{ minutes }}\u043C", "hours": "{{ hours }}\u0447", "days": "{{ days }}\u0434", "hoursAndMinutes": "{{ hours }}\u0447 {{ minutes }}\u043C" } }, "dateTime": { "quarterAndYear": "\u041A\u0432.{{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{currencyCode}} {{amountNumberValue}}", "phoneNumberWithExtension": "{{phoneNumber}}, \u0434\u043E\u0431\u0430\u0432. {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "ru": "static-7.794"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.794",
    "ar-eg": "static-7.794",
    "bg": "static-7.794",
    "bn": "static-7.794",
    "ca-es": "static-7.794",
    "cs": "static-7.794",
    "da": "static-7.794",
    "de": "static-7.794",
    "el": "static-7.794",
    "en-gb": "static-7.794",
    "en": "static-7.794",
    "es-mx": "static-7.794",
    "es": "static-7.794",
    "fi": "static-7.794",
    "fr-ca": "static-7.794",
    "fr": "static-7.794",
    "he-il": "static-7.794",
    "hr": "static-7.794",
    "hu": "static-7.794",
    "id": "static-7.794",
    "it": "static-7.794",
    "ja": "static-7.794",
    "ko": "static-7.794",
    "lt": "static-7.794",
    "ms": "static-7.794",
    "nl": "static-7.794",
    "no": "static-7.794",
    "pl": "static-7.794",
    "pt-br": "static-7.794",
    "pt-pt": "static-7.794",
    "ro": "static-7.794",
    "ru": "static-7.794",
    "sk": "static-7.794",
    "sl": "static-7.794",
    "sv": "static-7.794",
    "th": "static-7.794",
    "tl": "static-7.794",
    "tr": "static-7.794",
    "uk": "static-7.794",
    "vi": "static-7.794",
    "x-pseudo": "static-7.794",
    "zh-cn": "static-7.794",
    "zh-hk": "static-7.794",
    "zh-tw": "static-7.794"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:ru', {version: 'static-7.794'}); }
})();


