// @ts-expect-error not converted yet
import { reportError } from 'ContentUtils/helpers/sentry';
import { fetchPublicPlayer } from '../utils/api';
import { FETCH_VIDEO_SUCCESS, FETCH_VIDEO_FAILED, FETCH_VIDEO_ATTEMPTED, SET_PLAYER_ERROR, CONFIRMED_VISIBLE } from './ActionTypes';
export const loadVideo = (videoId, params) => (dispatch, getState, tracker) => {
  const parentDomain = params.parentOrigin ? params.parentOrigin.replace(/^https?:\/\//, '') : null;
  dispatch({
    type: FETCH_VIDEO_ATTEMPTED
  });
  fetchPublicPlayer(videoId, {
    parentDomain,
    pageId: params.pageId
  }).then(player => {
    dispatch(Object.assign({
      type: FETCH_VIDEO_SUCCESS
    }, player));
  }).catch(error => {
    reportError(error, {
      errorId: FETCH_VIDEO_FAILED
    });
    tracker.track('videoLoaded', {
      success: false,
      statusCode: error.status
    });
    dispatch({
      type: FETCH_VIDEO_FAILED,
      error
    });
  });
};
export const setAppError = appError => ({
  type: SET_PLAYER_ERROR,
  appError
});
export const confirmVisible = () => ({
  type: CONFIRMED_VISIBLE
});