import PortalIdParser from 'PortalIdParser';
import { createTracker as createPublicTracker } from 'usage-tracker-public';
import { createTracker as createAuthedAppTracker } from 'usage-tracker';
import { isAuthedAppPreview } from 'video-embed/utils/env';
import events from '../../events.yaml';
const lastKnownEventProperties = ['playerType', 'videoId', 'videoTitle'];
const IGNORE_USERAGENT_TOKENS = ['HubSpot Crawler'];
export const getSessionId = () => String(window.performance.timing.domLoading);
export const shouldIgnoreVisit = (navigator = window.navigator) => {
  return navigator.userAgent && IGNORE_USERAGENT_TOKENS.some(token => navigator.userAgent.includes(token));
};
export const getSecondsElapsed = () => window.performance.now() / 1000;
let tracker;

const createUsageTracker = properties => {
  if (tracker) {
    return tracker;
  }

  if (isAuthedAppPreview()) {
    tracker = createAuthedAppTracker({
      events,
      lastKnownEventProperties,
      properties
    });
  } else {
    tracker = createPublicTracker({
      events,
      bypassPool: true,
      isBeforeUnload: true,
      // important - use sendBeacon API to capture attention videoAttentionSpan as window/frame unloads
      lastKnownEventProperties,
      properties: Object.assign({}, properties, {
        hubId: PortalIdParser.get(),
        // we use deviceId for public tracking to ensure we don't affect contacts in portal 53
        // simply using when the player window/iframe loaded as we're not concerned with session-izing usage across videos
        deviceId: getSessionId(),
        email: null,
        hstc: null
      })
    });
  }

  return tracker;
};

export default createUsageTracker;