import Raven from 'Raven';
import { MessageTypes } from 'video-embed/constants';
import { debugLog } from 'video-embed/utils/debug';
import { trackPlayEvent, trackSecondsViewed } from './api';
import { getHlsTech } from './player';
const CHUNK_DURATION_SECONDS = 1;
const RETENTION_TRACKING_INTERVAL_SECONDS = 10; // only track `seconds-viewed` aka retention events for the first 1 hour, and disable loop mode

const RETENTION_MAX_SESSION_DURATION_SECONDS = 60 * 60;

const getVideoProperties = video => ({
  videoDuration: video.duration / 1000,
  videoId: video.videoId,
  videoTitle: video.title
});

function mapToObject(map) {
  const result = {};
  map.forEach((val, key) => {
    result[key] = val;
  });
  return result;
}

export class RetentionReporter {
  constructor(video, {
    embedId,
    sessionId,
    utk,
    pageMeta,
    postMessageToParent
  }, tracker, player) {
    this.trackedChunksLoaded = false;
    this.trackedCompletion = false;
    this.chunksLoaded = 0;
    this.totalChunksLoaded = 0;
    this.bytesLoaded = 0;
    this.totalBytesLoaded = 0;
    this.durationLoaded = 0;
    this.totalDurationLoaded = 0;
    this.video = video;
    this.embedId = embedId;
    this.sessionId = sessionId;
    this.utk = utk;
    this.pageMeta = pageMeta;
    this.chunksViewed = new Map();
    this.chunksViewedSinceReport = new Map();
    this.tracker = tracker;
    this.player = player;
    this.trackingStartedAt = new Date().getTime();

    if (postMessageToParent) {
      this.postMessageToParent = postMessageToParent;
    } else {
      this.postMessageToParent = () => null;
    }

    if (player) {
      player.ready(() => {
        this.setupHlsChunkTracking();
      });
    }
  }

  trackPlay() {
    const {
      sessionId,
      utk,
      pageMeta,
      video
    } = this;

    if (!utk || !pageMeta || this.playEventPromise) {
      return this.playEventPromise || Promise.resolve();
    }

    const eventData = Object.assign({}, pageMeta, {
      crmObjectId: video.crmObjectId,
      sessionId,
      utk
    });
    this.playEventPromise = trackPlayEvent(video.crmObjectId, sessionId, utk, pageMeta).then(() => {
      if (this.tracker) {
        this.tracker.track('playerInteraction', {
          action: 'tracked-play'
        });
      }

      this.postMessageToParent(MessageTypes.TRACKED_PLAY, eventData);
    }).catch(err => {
      console.error(err);
      const errorData = {
        status: err.status,
        responseJSON: err.responseJSON,
        requestBody: eventData
      };
      Raven.captureMessage(`Failed to track play event - status: ${err.status}`, {
        extra: errorData,
        tags: {
          status: err.status
        }
      });
    });
    return this.playEventPromise;
  }

  trackNewChunksViewed(endState = false) {
    if (!this.utk || !this.pageMeta || !this.chunksViewedSinceReport.size) {
      return;
    }

    const secondsToViews = mapToObject(this.chunksViewedSinceReport);
    const extra = Object.assign({}, this.pageMeta, {
      secondsToViews,
      endState
    });
    debugLog(`Tracking ${endState ? 'final' : 'interval'} seconds viewed`, secondsToViews);
    trackSecondsViewed(this.video.crmObjectId, this.sessionId, this.utk, extra).catch(err => {
      console.error(err);
      const errorData = {
        status: err.status,
        responseJSON: err.responseJSON,
        requestBody: {
          crmObjectId: this.video.crmObjectId,
          sessionId: this.sessionId,
          utk: this.utk,
          extra,
          endState
        }
      };
      Raven.captureMessage(`Failed to track seconds viewed events - status: ${err.status}`, {
        extra: errorData,
        tags: {
          status: err.status
        }
      });
    });
    this.chunksViewedSinceReport.clear();
    this.lastRetentionReported = Date.now();
  }

  fillRemainingSecondsViewed() {
    const videoDurationSeconds = this.video.duration / 1000;
    const totalChunks = Math.floor(videoDurationSeconds / CHUNK_DURATION_SECONDS);

    if (this.chunksViewed.size < totalChunks) {
      debugLog(`${totalChunks - this.chunksViewed.size} remaining chunks to track for looped video`);

      for (let i = 0; i < videoDurationSeconds; i++) {
        if (!this.chunksViewed.get(i) && !this.chunksViewedSinceReport.get(i)) {
          this.chunksViewedSinceReport.set(i, 1);
        }
      }
    }
  }

  onChunkViewed(chunkSeconds) {
    this.chunksViewed.set(chunkSeconds, (this.chunksViewed.get(chunkSeconds) || 0) + 1);
    this.chunksViewedSinceReport.set(chunkSeconds, (this.chunksViewedSinceReport.get(chunkSeconds) || 0) + 1); // base reporting interval on first second viewed of initial play

    if (!this.lastRetentionReported) {
      this.lastRetentionReported = Date.now();
    }

    const secondsSinceLastReport = (Date.now() - this.lastRetentionReported) / 1000;

    if (secondsSinceLastReport >= RETENTION_TRACKING_INTERVAL_SECONDS) {
      this.trackNewChunksViewed();
      this.trackHlsChunksLoaded();
    }
  }

  getViewDuration() {
    return this.chunksViewed.size * CHUNK_DURATION_SECONDS;
  }

  trackAttentionSpan(completed = false) {
    if (this.trackedCompletion) {
      return;
    }

    const {
      embedId,
      video,
      totalChunksLoaded,
      totalBytesLoaded,
      totalDurationLoaded
    } = this;

    if (this.tracker) {
      const props = Object.assign({}, getVideoProperties(video), {
        completed,
        viewDuration: this.getViewDuration(),
        chunksLoaded: totalChunksLoaded,
        megaBytesLoaded: totalBytesLoaded / 1000 / 1000,
        secondsLoaded: totalDurationLoaded
      });
      this.tracker.track('videoAttentionSpan', props);
      debugLog(`Tracking final attention span for player: ${embedId}`, props);
    }

    this.trackedCompletion = true;
  }

  setupHlsChunkTracking() {
    const hls = getHlsTech(this.player);

    if (!hls) {
      return;
    }

    hls.on('hlsFragLoaded', (_eventName, data) => {
      this.chunksLoaded += 1;
      this.totalChunksLoaded += 1;

      if (data.frag && data.frag.stats && data.frag.stats.total) {
        this.durationLoaded += data.frag.duration;
        this.totalDurationLoaded += data.frag.duration;
        this.bytesLoaded += data.frag.stats.total;
        this.totalBytesLoaded += data.frag.stats.total;
      }
    });
  }

  trackHlsChunksLoaded() {
    if (!this.chunksLoaded || !this.tracker) {
      return;
    }

    const {
      embedId,
      video,
      chunksLoaded,
      bytesLoaded,
      durationLoaded,
      totalChunksLoaded,
      totalBytesLoaded,
      totalDurationLoaded
    } = this;
    const props = Object.assign({}, getVideoProperties(video), {
      initial: !this.trackedChunksLoaded,
      viewDuration: this.getViewDuration(),
      chunksLoaded,
      megaBytesLoaded: bytesLoaded / 1000 / 1000,
      secondsLoaded: durationLoaded
    });
    this.tracker.track('hlsChunksLoaded', props);
    debugLog(`Tracking HLS chunks loaded for player: ${embedId}`, props);
    debugLog(`HLS chunk totals for player ${embedId}`, {
      totalChunksLoaded,
      totalBytesLoaded,
      totalDurationLoaded
    });
    this.chunksLoaded = 0;
    this.bytesLoaded = 0;
    this.durationLoaded = 0;
    this.trackedChunksLoaded = true;
  }

  getSessionDuration() {
    return (Date.now() - this.trackingStartedAt) / 1000;
  }

  hasViewedCompletely() {
    const videoDurationSeconds = this.video.duration / 1000;
    const totalChunks = Math.floor(videoDurationSeconds / CHUNK_DURATION_SECONDS);
    return this.chunksViewed.size >= totalChunks;
  }

  getTimeRangesDuration(timeRanges) {
    const durations = [];
    let i = 0;

    while (i < timeRanges.length) {
      durations.push(timeRanges.end(i) - timeRanges.start(i));
      i++;
    }

    return durations.sort()[durations.length - 1];
  }

}
export function setupRetentionTracking(video, player, {
  embedId,
  sessionId,
  utk,
  pageMeta,
  postMessageToParent
}, tracker) {
  const videoDurationSeconds = video.duration / 1000;
  const reporter = new RetentionReporter(video, {
    embedId,
    sessionId,
    utk,
    pageMeta,
    postMessageToParent
  }, tracker, player);
  debugLog(utk ? 'Setting up play & retention tracking for player' : 'No utk for play / retention tracking, setting up only amplitude tracking', pageMeta, reporter);
  let lastChunkStarted = 0;

  const handleTimeUpdate = () => {
    // videojs off is broken, so no way to detach `timeupdate` listener https://github.com/videojs/video.js/issues/5648
    if (reporter.trackedCompletion) {
      return;
    }

    const currentChunk = Math.floor(player.currentTime() / CHUNK_DURATION_SECONDS);

    if (lastChunkStarted === currentChunk - 1) {
      reporter.onChunkViewed(lastChunkStarted);
    }

    lastChunkStarted = currentChunk;

    if (reporter.getSessionDuration() >= RETENTION_MAX_SESSION_DURATION_SECONDS) {
      debugLog(`Ending retention tracking due to session duration reaching ${reporter.getSessionDuration()}`);
      reporter.trackNewChunksViewed(false);
      reporter.trackAttentionSpan(false);

      if (player.loop()) {
        player.loop(false);
      }
    }

    if (player.loop()) {
      // looped videos don't fire ended event so "end state" needs to be approached differently
      const duration = reporter.getTimeRangesDuration(player.played());

      if (duration >= videoDurationSeconds) {
        debugLog(`Ending retention tracking - TimeRanges with duration ${duration} indicate complete view`);
        reporter.fillRemainingSecondsViewed();
        reporter.trackNewChunksViewed(true);
        reporter.trackAttentionSpan(true);
      }

      if (reporter.hasViewedCompletely()) {
        debugLog('Ending retention tracking for looped video watched completely', videoDurationSeconds, reporter.getSessionDuration());
        reporter.trackNewChunksViewed(true);
        reporter.trackAttentionSpan(true);
      }
    }
  };

  const handleEnded = e => {
    debugLog('Ended retention tracking due to ended event', e);
    reporter.trackNewChunksViewed(true);
    reporter.trackAttentionSpan(reporter.hasViewedCompletely());
  };

  const handleVisibilityStateChange = () => {
    if (reporter.trackedCompletion || document.visibilityState !== 'hidden') {
      return;
    }

    reporter.trackHlsChunksLoaded();

    if (player.hasStarted()) {
      debugLog(`Ending retention tracking for player ${embedId} due to visibilityState change to: ${document.visibilityState}`);
      reporter.trackNewChunksViewed(true);
      reporter.trackAttentionSpan(false);
      document.removeEventListener('visibilitychange', handleVisibilityStateChange);
    }

    if (reporter.getSessionDuration() >= RETENTION_MAX_SESSION_DURATION_SECONDS) {
      debugLog('Detaching visibilitychange listener after 1 hour');
      document.removeEventListener('visibilitychange', handleVisibilityStateChange);
    }
  };

  player.on('playing', () => reporter.trackPlay());
  player.on('timeupdate', handleTimeUpdate);
  player.on('ended', handleEnded);
  document.addEventListener('visibilitychange', handleVisibilityStateChange);
  return reporter;
}