import enviro from 'enviro';
import { debugLog } from 'video-embed/utils/debug';
import { loadScript } from 'video-embed/utils/dom';
export const MODAL_FORM_WRAPPER_CLASSNAME = 'hsv-form-wrapper';
export const SUBMIT_BUTTON_SELECTOR = '.hs-form__actions__submit';
export const LIGHT_COLOR_VALUE_THRESHOLD = 155;
export function loadFormsJs() {
  const formsJsDomain = enviro.isQa() ? 'hsformsqa.net' : 'hsforms.net';
  const formsJsUrl = `//js.${formsJsDomain}/forms/new-embed-script.js`;
  debugLog(`Loading forms JS at ${formsJsUrl}`);
  return loadScript(formsJsUrl);
}