import * as browserHelper from '../client/browserHelper';
import * as cookieHelper from '../client/cookieHelper';
import { lWindow } from '../common/helpers';

const getItem = (key = '') => {
  let value = '';

  if (browserHelper.hasLocalStorage) {
    try {
      value = lWindow.localStorage.getItem(key) || '';
    } catch (err) {
      value = cookieHelper.get(key);
    }
  }

  return value;
};

const setItem = (key = '', value = '') => {
  if (browserHelper.hasLocalStorage) {
    try {
      lWindow.localStorage.setItem(key, value || '');
    } catch (err) {
      cookieHelper.set(key, value);
    }
  }

  return value;
};

export default {
  getItem,
  setItem
};